.button-container {
  & > * {
    margin-left: 5px;
  }
}
.form-container, .list-container, .view-container {
  background-color: $white;
  border-radius: 2px;
  box-shadow: 1px 1px 5px $black-transparent-25;
  padding: 10px;
  position: relative;

  .title {
    font-size: $default-size + 4px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
.grid-options {
  width: 100px;
  text-align: center !important;

  & > * {
    margin: 0;
  }

  button {
    border: none !important;

    span {
      font-size: 18px;
      color: $gray-dark-3 !important;
    }
  }
}
.header-container {
  margin-bottom: 10px;

  .page-title {
    color: $carbon-light;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }
}
.settings-options {
  .settings-option {
    padding: 10px;
    border-bottom: 1px solid $gray;
    color: $default-color-text;
    font-size: 14px;
    font-weight: 300;

    &:last-child {
      border: none;
    }
    &:hover {
      border-bottom-color: $carbon;
      cursor: pointer;
      background: $default-color-text;
      color: $sidebar-color-active;
    }

    i {
      width: 20px;
    }
  }
}
.selection-column {
  text-align: center;
  width: 50px;
}
